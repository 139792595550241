@import 'defaults';

.Footer {
  flex-grow: 0;
  color: $white;
  background-color: #1d1d1d;

  .jumbotron {
    background-color: #1d1d1d;
  }
}
