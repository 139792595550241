@import 'defaults';

.Main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Main-content {
  flex-grow: 1;
}
