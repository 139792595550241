$video-react-text-font-family: 'Roboto Condensed', sans-serif;

@import '~video-react/styles/scss/video-react';
@import '~react-h5-audio-player/src/styles';
@import 'defaults';

html, body, #root {
  height: 100%;
  font-family: 'Roboto Condensed', sans-serif;
}

.logo .st0 {
  fill: #ededed;
}

.font-md {
  font-size: 1.2rem;
}

.table {
  .first {
    td, th {
      border-top-width: 0;
    }
  }

  &.selectable {
    td, th {
      cursor: pointer;
    }
  }
}

.card-header {
  &.lead {
    button {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.selectable {
  cursor: pointer;
}

.aspect-ratio-1 {
  padding-top: 100%;
  position: relative;
}

.aspect-ratio-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.rich-text {
  white-space: pre-wrap;
}

.video-react {
  .video-react-big-play-button {
    display: none;
  }
}

.rhap_container {
  box-shadow: none;

  .rhap_play-pause-button {
    width: 45px;
    height: 45px;
  }
}
