@import 'defaults';

.Header {
  background-color: theme-color('primary');
  flex-grow: 0;

  .logo {
    height: 7vmin;
    pointer-events: none;
  }
}
