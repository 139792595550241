.QRCode-expired {
  opacity: 0.15;
}

.Retry {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  button {
    margin: auto;
  }
}
